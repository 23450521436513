@import "./prismjs";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
}

a {
  text-decoration: none;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}

.css-1jgv6z-ToastContainer {
  top: 110px !important;
  .css-9z2we-ToastElement {
    height: 75px !important;
  }
  .react-toast-notifications__toast__content {
    font-size: 12px;
    font-family: Roboto;
  }
  .css-80a6v4-Icon {
    top: 40%;
  }
}

.css-3y3n1b {
  box-sizing: border-box !important;
  max-height: 100% !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  position: fixed !important;
  z-index: 1000 !important;
  top: 100px !important;
  right: 0px !important;
}

//breadcrumbs

.MuiBreadcrumbs-ol {
  text-decoration: none !important;
}
